<template>
	<v-container
		v-if="$can([titlePerms + '_list'])"
		id="crud"
		fluid
		tag="section"
	>
		<v-card>
			<v-toolbar flat color="white">
				<v-toolbar-title>Administración de {{ title }}</v-toolbar-title>
			</v-toolbar>

			<v-data-table
				:headers="showHeaders"
				:items="filteredData"
				:search="search"
				sort-by="secret"
				class="elevation-1"
			>
				<template v-slot:[`header.nombre`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`header.activo`]="{ header }">
					<filter-component
						v-bind:header="{ header }"
						v-bind:filters="filters"
					></filter-component>
				</template>
				<template v-slot:[`item.activo`]="{ item }">
					<div :class="$getCss(item.activo, 'activo')" dark>
						{{ $getValue(item.activo, "activo") }}
					</div>
				</template>
				<template v-slot:[`item.created_at`]="{ item }">
					{{ item.created_at }}
				</template>
				<template v-slot:[`item.updated_at`]="{ item }">
					{{ item.updated_at }}
				</template>

				<template v-slot:top>
					<v-toolbar flat color="white" class="pr-4">
						<v-row>
							<v-col sm="6" class="pt-20">
								<v-text-field
									v-model="search"
									append-icon="mdi-magnify"
									:label="'Busqueda de ' + title"
									single-line
									hide-details
								></v-text-field>
							</v-col>
							<v-col sm="2"></v-col>
							<v-col sm="3" class="">
								<v-autocomplete
									v-model="selectedHeaders"
									:items="headers"
									label="Columnas Visibles"
									multiple
									return-object
								>
									<template v-slot:selection="{ item, index }">
										<v-chip v-if="index < 2">
											<span>{{ item.text }}</span>
										</v-chip>
										<span v-if="index === 2" class="grey--text caption"
											>(otras {{ selectedHeaders.length - 2 }}+)</span
										>
									</template>
								</v-autocomplete>
							</v-col>
							<v-col sm="1">
								<v-dialog v-model="dialog" max-width="50%" persistent>
									<template
										v-if="$can([titlePerms + '_create'])"
										v-slot:activator="{ on, attrs }"
									>
										<v-btn
											:color="$cv('principal')"
											dark
											class="mx-2"
											fab
											v-bind="attrs"
											v-on="on"
											><v-icon blue>mdi-plus</v-icon></v-btn
										>
									</template>
									<v-card>
										<v-form ref="form" v-model="valid" lazy-validation>
											<v-toolbar dark :color="$cv('principal')">
												<v-btn icon dark @click="dialog = false">
													<v-icon>mdi-close</v-icon>
												</v-btn>
												<v-toolbar-title>{{ formTitle }}</v-toolbar-title>
												<v-spacer></v-spacer>
												<v-toolbar-items>
													<v-btn
														dark
														text
														@click="$save()"
														v-if="!vista"
														:disabled="!valid"
														>Guardar</v-btn
													>
												</v-toolbar-items>
											</v-toolbar>

											<v-card-text>
												<v-container>
													<v-row>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-autocomplete
																:items="tipo_entidades"
																item-value="id"
																item-text="nombre"
																label="Tipo Entidad"
																v-model="editedItem.tipo_entidad_id"
																:rules="[$rulesRequerido]"
																:disabled="vista"
															></v-autocomplete>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-text-field
																v-model="editedItem.cuit"
																label="CUIT/CUIL"
																hint="Ingrese solo números"
																maxLength="11"
																class="input-h1"
																v-mask="'###########'"
																prepend-inner-icon="mdi-card-account-details"
																:disabled="vista"
																:rules="[$rulesRequerido]"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-text-field
																v-model="editedItem.nombre"
																label="Entidad"
																:disabled="vista"
																:rules="[
																	$rulesRequerido,
																	$rulesAlfaNum,
																	$rulesMax500,
																]"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-autocomplete
																:items="provincias"
																item-value="id"
																item-text="nombre"
																label="Provincia"
																:disabled="vista"
																v-model="editedItem.datos.provincia"
																:rules="[$rulesRequerido]"
																@change="
																	getPartidos(editedItem.datos.provincia.id)
																"
																return-object
															></v-autocomplete>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-autocomplete
																:items="partidos"
																item-value="id"
																item-text="nombre"
																label="Partido"
																:disabled="vista"
																v-model="editedItem.datos.partido"
																:rules="[$rulesRequerido]"
																@change="
																	getCiudades(editedItem.datos.partido.id)
																"
																return-object
															></v-autocomplete>
														</v-col>
														<v-col cols="12" md="12" sm="12" class="py-0">
															<v-autocomplete
																:items="ciudades"
																item-value="id"
																item-text="nombre"
																label="Ciudad"
																:disabled="vista"
																v-model="editedItem.datos.ciudad"
																:rules="[$rulesRequerido]"
																return-object
															></v-autocomplete>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-text-field
																v-model="editedItem.datos.email"
																label="Email"
																:disabled="vista"
																:rules="[$rulesMail, $rulesMax500]"
															></v-text-field>
														</v-col>
														<v-col cols="12" md="6" sm="12" class="py-0">
															<v-text-field
																v-model="editedItem.datos.telefono"
																label="Telefono"
																:disabled="vista"
																:rules="[$rulesTelefono]"
															></v-text-field>
														</v-col>
													</v-row>
												</v-container>
											</v-card-text>
										</v-form>
									</v-card>
								</v-dialog>
							</v-col>
						</v-row>
					</v-toolbar>
				</template>
				<template v-slot:[`item.actions`]="{ item }">
					<v-icon
						v-if="$can([titlePerms + '_update'])"
						small
						:color="$cv('btnEditar')"
						title="Editar"
						@click="
							vista = false;
							$editItem(item.id);
						"
					>
						mdi-pencil
					</v-icon>
					<v-icon
						v-if="$can([titlePerms + '_read'])"
						small
						:color="$cv('btnVista')"
						title="Ver"
						@click="
							vista = true;
							$editItem(item.id);
						"
					>
						mdi-eye-outline
					</v-icon>
					<v-icon
						v-if="$can([titlePerms + '_changestatus'])"
						small
						:color="item.activo != 0 ? $cv('btnActivo') : $cv('btnInactivo')"
						:title="
							item.activo != 0 ? $cv('titleDesactivar') : $cv('titleActivar')
						"
						@click="$enabledItem(item)"
					>
						mdi-archive-arrow-down
					</v-icon>
					<v-icon
						v-if="$can([titlePerms + '_delete'])"
						small
						:color="$cv('btnEliminar')"
						title="Eliminar"
						@click="$deleteItem(item.id, item.nombre)"
					>
						mdi-delete
					</v-icon>
					<!-- <v-icon
            small
            color="yellow"
            title="Imprimir"

            @click="$pdfExport(item.id)"
          >
            mdi-file-document
          </v-icon>-->
				</template>
			</v-data-table>
		</v-card>

		<v-snackbar
			v-model="snackbar"
			:bottom="true"
			:color="color"
			:timeout="timeout"
		>
			<div v-html="text"></div>

			<template v-slot:action="{ attrs }">
				<v-btn dark text v-bind="attrs" @click="snackbar = false">
					Cerrar
				</v-btn>
			</template>
		</v-snackbar>
		<vue-confirm-dialog></vue-confirm-dialog>
	</v-container>
</template>

<script>
	function title() {
		return "Entidades";
	}

	export default {
		data: (vm) => ({
			valid: true,
			nowDate: new Date().toISOString().slice(0, 10),
			_method: "PUT",
			autoGrow: true,
			rows: 1,
			title: title(),
			dessertActivo: "",
			route: "entidades",
			titlePerms: title().toLowerCase(),
			menu: false,
			modal: false,
			menu2: false,
			dialog: false,
			snackbar: false,
			visible: true,
			text: "Registro Insertado",
			color: "success",
			timeout: 4000,
			rules: [(v) => v.length <= 500 || "Max 500 caracteres"],
			search: "",
			vista: false,
			users: false,
			provincias: [],
			partidos: [],
			ciudades: [],

			headers: [
				{
					text: "Id",
					align: "start",
					sortable: false,
					value: "id",
				},
				{ text: "Cuit", filterable: true, value: "cuit" },
				{ text: "Entidad", filterable: true, value: "nombre" },
				{ text: "Tipo Entidad", filterable: true, value: "tipo_entidad" },
				{ text: "Provincia", filterable: true, value: "datos.provincia.nombre" },
				{ text: "Partido", filterable: true, value: "datos.partido.nombre" },
				{ text: "Ciudad", filterable: true, value: "datos.ciudad.nombre" },
				{ text: "Activo", value: "activo" },
				{ text: "Actualizado", value: "updated_at" },
				{ text: "Acciones", value: "actions", sortable: false },
			],

			desserts: [],
			editedIndex: -1,
			tipo_entidades: [],
			editedItem: {
				nombre: "",
				tipo_entidad_id: "",
				cuit: {},
				id: "",
				datos: {
					provincia: {},
					partido: {},
					ciudad: {},
					email: "",
					telefono: "",
				},
			},
			defaultItem: {
				nombre: "",
				tipo_entidad_id: "",
				cuit: {},
				id: "",
				datos: {
					provincia: {},
					partido: {},
					ciudad: {},
					email: "",
					telefono: "",
				},
			},
			filters: {
				nombre: "",
				activo: "",
			},
			filterKey: [],
			selectedHeaders: [],
		}),

		computed: {
			formTitle() {
				return this.editedIndex === -1
					? "Registrar " + this.title
					: this.vista == false
					? "Editar " + this.title
					: "Ver " + this.title;
			},
			filteredData() {
				return this.$filteredData().data !== undefined
					? this.$filteredData().data
					: this.$filteredData();
			},
			showHeaders() {
				return this.headers.filter((s) => this.selectedHeaders.includes(s));
			},
		},

		watch: {
			dialog(val) {
				if (!val) {
					this.vista = false;
					this.$close();
				}
			},
		},

		created() {
			this.$initialize();
			this.$getData(["tipo_entidades", "provincias"]);

			this.selectedHeaders = this.headers;
		},

		methods: {
			filterByNombre(item) {
				return this.$filterBy(item, "nombre");
			},
			filterByActivo(item) {
				return this.$filterBy(item, "activo");
			},
			formatDate(timestamp) {
				console.log(timestamp);
				if (timestamp === null) {
					return "";
				}

				const date = new Date(timestamp);
				const day = date.getDate().toString().padStart(2, "0");
				const month = (date.getMonth() + 1).toString().padStart(2, "0");
				const year = date.getFullYear();
				return `${day}/${month}/${year}`;
			},

			async getPartidos(provincia_id) {
				Swal.alertGetInfo("Obteniendo Partidos <br><b></b>");
				this.partidos = [];
				let url =
					process.env.VUE_APP_API_URL_PERSONS +
					"api/provincias/" +
					provincia_id +
					"/partidos";

				this.$axiosApi
					.sendRequestExternal(url, "get")
					.then((r) => {
						if (r.data.data) {
							console.log("partidos");
							console.log(r.data.data);
							this.partidos = r.data.data;
							Swal.close();
						}
					})
					.catch(function (error) {
						Swal.close();
						console.log(error);
					});
			},
			async getCiudades(partido_id) {
				Swal.alertGetInfo("Obteniendo Ciudades <br><b></b>");
				this.ciudades = [];
				let url =
					process.env.VUE_APP_API_URL_PERSONS +
					"api/partidos/" +
					partido_id +
					"/localidades";

				this.$axiosApi
					.sendRequestExternal(url, "get")
					.then((r) => {
						if (r.data.data) {
							console.log("ciudades");
							console.log(r.data.data);
							this.ciudades = r.data.data;
							Swal.close();
						}
					})
					.catch(function (error) {
						Swal.close();
						console.log(error);
					});
			},
		},
		mounted() {
			console.log("Componente " + this.baseUrl + " creado");
		},
	};
</script>
